import { Layout } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppHeader from 'src/components/01.layout/header/AppHeader';
import { RootState } from 'src/store';
import './styles.scss';

const { Content } = Layout;

export const formatDate = 'DD-MM-YYYY HH:mm:ss';

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

const LayoutComponent: React.FC<ILayoutProps> = ({ children }) => {
  const userTheme = useSelector((state: RootState) => state.theme);
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const handleOpenMenuMobile = (bool: boolean) => {
    setIsOpenMenuMobile(bool);
  };

  return (
    <div id="layout">
      <Layout className="container">
        <AppHeader isOpenMenuMobile={isOpenMenuMobile} setIsOpenMenuMobile={handleOpenMenuMobile} />
        <Layout className={`site-layout ${userTheme}`}>
          <Content className="site-layout-background">
            <div>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutComponent;
