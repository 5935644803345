import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import './styles.scss';

const { Content } = Layout;

export const formatDate = 'DD-MM-YYYY HH:mm:ss';

interface ILayoutProps {
  children?: React.ReactNode;
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const userTheme = useSelector((state: any) => state.theme);
  return (
    <Layout className="container">
      <Layout className={`site-layout ${userTheme}`}>
        <Content className="site-layout-background">
          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDefault;
