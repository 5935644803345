import loadable, { DefaultComponent } from '@loadable/component';
import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import HelpCenterPage from 'src/pages/help-center';
import { ExchangePageLoading } from 'src/pages/loadings';
import Layout from '../components/01.layout/layoutDefault';
import { LoadingPage } from './components/LoadingPage';

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.helpCenter())) {
    return <ExchangePageLoading />;
  }
  return <LoadingPage />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import('./components/NotFound'));

const LayoutDashboard: React.FC<RouteProps> = (props) => {
  return (
    <Layout>
      <Route {...props} />
    </Layout>
  );
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <LayoutDashboard exact path="/" render={() => <Redirect to={PATHS.helpCenter()} />} />
      <LayoutDashboard exact path={`${PATHS.helpCenter()}`} component={HelpCenterPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
