import '@ant-design/flowchart/dist/index.css';
import 'antd/dist/antd.css';
import BigNumber from 'bignumber.js';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'src/assets/scss/_themes.scss';
import 'src/assets/scss/variable.scss';
import './App.scss';
import Layout from './components/01.layout';
import ToastContext from './contexts/toast';
import './index.scss';
import Routes from './routes/routes';

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <ToastContext />
        <Routes />
      </Layout>
    </BrowserRouter>
  );
};

export default App;
