import React from 'react';
import { Collapse } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import './styles.scss';

const { Panel } = Collapse;
interface Props {
  question: string;
  answer: string;
}
const FaqsCollapse = ({ question, answer }: Props) => {
  return (
    <div className="faq-collapse">
      <div className="faq-collapse-child">
        <Collapse
          accordion={false}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <div className="custom-expand-icon">
              {isActive ? <MinusOutlined /> : <PlusOutlined />}
            </div>
          )}
          bordered={false}
        >
          <Panel header={question} key="1">
            <div className="faq-answer">{answer}</div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default FaqsCollapse;
