import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactComponent as AppLogo } from 'src/assets/icons/header/axor/axor-logo.svg';
import { PATHS } from 'src/constants/paths';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { RootState } from 'src/store';
import './styles.scss';

const { Header } = Layout;

const logoMobileWidth = 90;
const logoDesktopWidth = 107;

export interface ConnectButtonProps {
  account?: {
    address: string;
    balanceDecimals?: number;
    balanceFormatted?: string;
    balanceSymbol?: string;
    displayBalance?: string;
    displayName: string;
    ensAvatar?: string;
    ensName?: string;
    hasPendingTransactions: boolean;
  };
  chain?: {
    hasIcon: boolean;
    iconUrl?: string;
    iconBackground?: string;
    id: number;
    name?: string;
    unsupported?: boolean;
  };
  mounted: boolean;
  openAccountModal: () => void;
  openChainModal: () => void;
  openConnectModal: () => void;
  accountModalOpen: boolean;
  chainModalOpen: boolean;
  connectModalOpen: boolean;
}

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
}

const AppHeader: React.FC<Props> = () => {
  const userTheme = useSelector((state: RootState) => state.theme);
  const { isMobile } = useWindowSize();
  const history = useHistory();

  const handleClickLogo = () => {
    history.push(`${PATHS.helpCenter()}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Header className={`site-layout-header ${userTheme}`}>
      <div className="sidebar-wrapper">
        <div className="logo-section" onClick={() => handleClickLogo()}>
          <AppLogo
            width={!isMobile ? logoDesktopWidth : logoMobileWidth}
            color={userTheme === THEME_MODE.DARK ? '#fff' : '#002E26'}
          />
        </div>
      </div>
    </Header>
  );
};

export default React.memo(AppHeader);
